<template>
  <cta-base>
    <p class="cta-block-inner-text">
      Kaufen Sie Strompakete zu günstigen Festpreisen und schützen Sie sich so
      vor zukünftigen Preiserhöhungen – egal wie lange Sie benötigen, um diesen
      Strom zu verbrauchen.
    </p>

    <cta-list-base>
      <cta-list-item
        v-for="(item, idx) in ctaAuthCode"
        :key="idx"
        :title="item"
        class="text-style-medium"
      />
    </cta-list-base>
  </cta-base>
</template>

<script>
import { ctaAuthCode } from '@/placeholder/cta';
import CtaListItem from '@/components/next/cta/cta-list-item.vue';
import CtaListBase from '@/components/next/cta/cta-list-base.vue';
import CtaBase from '@/components/next/cta/cta-base.vue';

export default {
  components: { CtaListItem, CtaListBase, CtaBase },

  data: () => ({ ctaAuthCode })
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
