<template>
  <div class="cta-block">
    <div class="cta-block-image"></div>
    
    <div class="cta-block-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
