<template>
  <div>
    <div class="heading-col auth-code-block">
      <h1 class="color-primary-100 with-margin mobile-h2">
        Haben Sie einen Code?
      </h1>

      <p class="color-accent-300">
        Bitte geben Sie Ihren persönlichen Aktionscode ein, den Sie in unserem
        Anschreiben erhalten haben.
      </p>
    </div>

    <div class="form-grid form-grid-col">
      <div class="form-grid-item auth">
        <input-regular
          v-model="fieldsOrder.token.value"
          :validation="fieldsOrder.token"
          type="text"
          label="Ihr Aktionscode"
          :showLabel="true"
        />

        <button-regular
          :isLoading="isLoading"
          class="button-accent"
          @click="getOffer()"
        >
          Zum Angebot
        </button-regular>
      </div>
    </div>
  </div>
</template>

<script>
import { fieldsOrder } from '@/placeholder/fieldsValidation';
import InputRegular from '@/components/next/input/input-regular.vue';
import ButtonRegular from '@/components/next/button-regular.vue';

export default {
  components: { InputRegular, ButtonRegular },

  data: () => ({
    isLoading: false,
    fieldsOrder
  }),

  methods: {
    getOffer() {
      if (this.fieldsOrder.token.value === '') {
        this.fieldsOrder.token.valid = false;
        this.fieldsOrder.token.error = 'Dieses Feld muss angegeben werden';

        return;
      }

      this.fieldsOrder.token.valid = true;
      this.passToPackage();
    },

    passToPackage() {
      this.isLoading = true;

      this.$store
        .dispatch('user/GET_OFFER_DATA', {
          offer: this.fieldsOrder.token.value,
          push: true
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
