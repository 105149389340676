<template>
  <main id="auth-code" class="next-container">
    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100">Jetzt neu</h1>

        <h2 class="color-primary-100">die eprimo Strompakete</h2>
      </div>

      <provide-code-banner />

      <provide-code-form />
    </section-base>
  </main>
</template>

<script>
import ProvideCodeForm from '../../components/next/provide-code-form.vue';
import ProvideCodeBanner from '../../components/next/provide-code-banner.vue';
import SectionBase from '@/components/next/section-base.vue';

export default {
  components: { ProvideCodeForm, ProvideCodeBanner, SectionBase }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
